@import url("https://use.typekit.net/qyd2uij.css");

tspan,
span,
html,
body {
    font-family: "neo-sans", sans-serif;
}
body,
.bgImage {
    @apply bg-darkblue text-white text-base bg-no-repeat bg-top;
    background-image: url("../public/bg.jpg");
}
input,
select,
number {
    @apply bg-darkblue text-white block focus:outline-none w-full p-2 mb-2;
}
.chart {
    @apply bg-dawnblue rounded-3xl py-6 px-2 h-full shadow-2xl;
}
select {
    @apply appearance-none;
}
.select {
    @apply relative;
}
.select:after {
    @apply inline-block text-white absolute right-3 top-2 text-lg transform rotate-90;
    content: "›";
}

.App {
    @apply items-center justify-center mt-20;
}
.dynamicField {
    @apply bg-nightblue inline-block text-base xl:text-xl px-1 rounded-lg border border-gray-600;
}
.setItem {
    @apply mb-3 rounded-md;
}
.setItem label {
    @apply font-bold text-xs xl:text-base uppercase mb-2 block mt-4;
}
.range {
    @apply appearance-none w-full bg-darkblue transition-opacity p-0 h-1 mt-3;
}

.range::-webkit-slider-thumb {
    cursor: pointer;
    @apply appearance-none w-4 h-4 bg-nordan border-0 rounded-full;
}
.range::-moz-range-thumb {
    cursor: pointer;
    @apply appearance-none w-4 h-4 bg-nordan border-0 rounded-full;
}

.apexcharts-gridline {
    @apply opacity-0;
}
.apexcharts-tooltip {
    @apply text-nightblue;
}

h1,
h2 {
    @apply uppercase;
}

h1 {
    @apply text-3xl font-black text-white mb-3 tracking-widest;
}
h2 {
    @apply text-2xl font-black text-white tracking-widest  border-white mt-20;
}
h3 {
    @apply text-xl font-black text-white uppercase;
}
h4 {
    @apply text-base text-gray-400;
}
.h3-data {
    @apply text-base mb-2 xl:mb-0.5 block xl:flex justify-between;
}
.a-data {
    @apply underline block;
}
.regionSelect {
    width: 550px;
    max-width: 100%;
    @apply h-auto;
}
.regionSelect path {
    @apply stroke-nightblue stroke-2;
}
.regionSelect .inactive {
    @apply fill-darkblue;
}
.regionSelect .active {
    @apply fill-nordanLight;
}
.regionSelect .selectable {
    @apply fill-nordan hover:fill-nordanLight cursor-pointer;
}
.apexcharts-tooltip.apexcharts-theme-dark {
    @apply !text-nightblue;
}
.energikalk-col1,
.energikalk-col2 {
    @apply p-2;
}
.energikalk-col2 {
    @apply text-right;
}
